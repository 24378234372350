import React, { useState, useEffect } from 'react';

export default function HomepageMainSection() {
  const [text, setText] = useState('');
  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsTextVisible(true), 2000);
  }, []);

  useEffect(() => {
    const paragraph =
      "Bien, le Compendium est calibré. La console de recherche semble fonctionnelle, il faut se référer aux réglettes graduées de la carte et taper la requête. 3 caractères requis : Une lettre et deux chiffres (ne pas oublier le 0 pour les neuf premiers chiffres) et l’annuaire devrait trouver le lieu demandé. L’itinéraire idéal a été tracé directement sur la carte et chaque glyphe a été référencé pour suivre le Protocole Kerberus. En annexes ci-dessous, j’ai compilé quelques documents liés à cette aventure afin de tenter de démêler ce puzzle et de comprendre ce qui se trame ici. Bonne chance Opérateur. Foxtrot//";

    let index = 0;
    const intervalId = setInterval(() => {
      if (isTextVisible) {
        setText(paragraph.slice(0, index));
        index++;
      }
      if (index > paragraph.length) {
        clearInterval(intervalId);
      }
    }, 30);

    return () => clearInterval(intervalId);
  }, [isTextVisible]);

  return (
    <>
      <div className="main">
        {/* <p className='main__paragraph'>"Bien, le Compendium est calibré. La console de recherche semble fonctionnelle, il faut se référer aux réglettes graduées de la carte et taper la requête. 3 caractères requis : Une lettre et deux chiffres (ne pas oublier le 0 pour les neuf premiers chiffres) et l’annuaire devrait trouver le lieu demandé. L’itinéraire idéal a été tracé directement sur la carte et chaque glyphe a été référencé pour suivre le Protocole Kerberus. En annexes ci-dessous, j’ai compilé quelques documents liés à cette aventure afin de tenter de démêler ce puzzle et de comprendre ce qui se trame ici. Bonne chance Opérateur. Foxtrot//";</p> */}
        {isTextVisible && <p className="main__paragraph">{text}</p>}
      </div>
    </>
  );
}
