import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import FormData from 'form-data';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import API from '../API';

function PostEdit({ postCode, ...props }) {
    const { serverUrl } = useContext(API);
    const { notify, accessToken, setAccessToken } = props;
    const [code, setCode] = useState('');
    const [title, setTitle] = useState('');
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [riddles, setRiddles] = useState([]);
    const [selectedRiddle, setSelectedRiddle] = useState([]);
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState([]);
    const [seeUploaded, setSeeUploaded] = useState(selectedImage.length > 0)
    const formData = new FormData();
    const [selectedPost, setSelectedPost] = useState();
    const [prev, setPrev] = useState([]);
    const [next, setNext] = useState([]);
    const [dropArea, setDropArea] = useState(null);

    useEffect(() => {
        const dropArea = document.querySelector(".drop_box");
        if (dropArea) {
            const dragText = dropArea.querySelector("header");

            const handleDragOver = (e) => {
                e.preventDefault();
                dropArea.classList.add("dragging");
                dragText.textContent = "Relâchez le fichier";
            };

            const handleDragLeave = () => {
                dropArea.classList.remove("dragging");
                dragText.textContent = "Déposez le fichier ici";
            };

            const handleDrop = (e) => {
                e.preventDefault();
                dropArea.classList.remove("dragging");
                dragText.textContent = "Déposez le fichier ici";
                const file = e.dataTransfer.files[0];
                setFile(file);
            };

            dropArea.addEventListener("dragover", handleDragOver);
            dropArea.addEventListener("dragleave", handleDragLeave);
            dropArea.addEventListener("drop", handleDrop);

            setDropArea(dropArea);
        }
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        dropArea.classList.add("dragging");
        dragText.textContent = "Relâchez le fichier";
    };

    const handleDragLeave = () => {
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
    };

    const handleDrop = (e) => {
        e.preventDefault();
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
        const file = e.dataTransfer.files[0];
        setFile(file);
    };

    let dragText = useRef(null);

    //Met à jour le fichier quand il est séléctionné

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleImageSelect = (event) => {
        event.preventDefault();
        document.getElementById('image').click();
    };

    //Affiche le select si la checkbox est coché

    const handleCheckboxChange = () => {
        setSeeUploaded(prevSeeUploaded => !prevSeeUploaded);
    };


    //Fonction qui va être utilisé si une image doit être upload,
    //elle va utiliser l'API d'imgur pour récupérer un lien et le stocker dans la BDD

    const createImage = async () => {
        try {
            let imageUrl;

            //vérifie si la checkbox est coché, si oui il va récupérer l'id de l'image
            //pour sortir de la fonction

            if (seeUploaded === true) {
                imageUrl = selectedImage.map((id) => image.find((img) => img._id === id)._id
                );
                return imageUrl;

                //si la checkbox n'est pas coché il va prendre le fichier et l'upload sur imgur

            } else {
                const response = await axios({
                    method: "post",
                    url: "https://api.imgur.com/3/image",
                    data: formData,
                    headers: {
                        Authorization: "Client-ID 41e0d680636e63d",
                        "Content-Type": "multipart/form-data",
                    },
                });

                //récupère l'URL De l'image uploadé et poste l'url de l'image dans la collection

                imageUrl = response.data.data.link;

                const imageResponse = await axios.post(
                    `${serverUrl}/image/create`,
                    {
                      url: imageUrl,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                    }
                  );

                const imageId = imageResponse.data.image._id;

                return imageId;
            }

        } catch (error) {
            console.log(error);
        }
    };

    formData.append("image", file);

    //récupère toutes les données pour pouvoir les modifier

    useEffect(() => {
        axios.get(`${serverUrl}/image`)
            .then(response => {
                setImage(response.data.Images);
            })
            .catch(error => {
                console.error(error);
            });
        axios.get(`${serverUrl}/lieu`)
            .then(response => {
                setLocations(response.data.Lieux);
            })
            .catch(error => {
                console.error(error);
            });

        axios.get(`${serverUrl}/enigme`)
            .then(response => {
                setRiddles(response.data.Enigmes);
            })
            .catch(error => {
                console.error(error);
            });
    }, [serverUrl]);

    useEffect(() => {
        const handlePostSelect = async (postCode) => {
            try {
                const response = await axios.get(`${serverUrl}/post/${postCode}`);
                if (response.status === 200) {
                    setSelectedPost(response.data.post);
                }
            } catch (error) {
                notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
            }
        };
        if (postCode) {
            handlePostSelect(postCode);
        }
    }, [postCode, serverUrl, notify]);

    useEffect(() => {
        if (selectedPost) {
            setCode(selectedPost.code);
            setTitle(selectedPost.title);
            setSelectedLocation(selectedPost.lieu);
            setSelectedRiddle(selectedPost.enigme);
            setSelectedImage([selectedPost.image]);
            setPrev(selectedPost.prev);
            setNext(selectedPost.next);
            setSeeUploaded(selectedPost.image !== null);
        }
    }, [selectedPost]);

    //va mettre à jour la BDD avec les nouvelles informations

    const handleUpdate = async (e) => {
        e.preventDefault();

        const imageId = await createImage();

        if (!imageId) {
            notify('Erreur lors de la création de l\'image');
            return;
        }

        const postData = {
            code: code,
            title: title,
            image: imageId,
            lieu: selectedLocation,
            enigme: selectedRiddle,
            prev: prev,
            next: next
        };

        try {
            if (!selectedPost) {
                notify("Aucun post sélectionné", "error");
                return;
            }

            const response = await axios.patch(`${serverUrl}/post/${selectedPost._id}`, postData, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                notify(`${response?.data?.message || ""}`, "success");
            }

        } catch (error) {
            setAccessToken("");
            sessionStorage.removeItem("accessToken");
            notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
            console.log(error);
        }
    };

    return (
        <>
            <form onSubmit={handleUpdate} className="form-container">
                <div>
                    <label htmlFor="code">Code:</label>
                    <input type="text" id="code" value={code} onChange={(e) => setCode(e.target.value.toUpperCase())} />
                </div>
                <div>
                    <label htmlFor="title">Titre:</label>
                    <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className='select'>
                    <div className='checkbox-align'>
                        <label htmlFor="image" className='checkbox-align__label'>Choisir une image déjà importé:</label>
                        <label htmlFor="checkbox" className='toggle-switch'>
                            <input type="checkbox" name="seeUploadedCheckbox" id="seeUploadedCheckbox" className='toggle-input' onChange={handleCheckboxChange} checked={seeUploaded} />
                            <label htmlFor="seeUploadedCheckbox" className='toggle-label'></label>
                        </label>
                    </div>
                    {seeUploaded ? (
                        <Select
                            isMulti
                            value={selectedImage.map((id) => ({
                                value: id,
                                label: image && image.find((image) => image._id === id).url
                            }))}
                            options={image.map((image) => ({
                                value: image._id,
                                label: (
                                    <div>
                                        <img src={image.url} alt={image._id} style={{ height: "50px" }} />
                                        <span>{image.url}</span>
                                    </div>
                                )
                            }))}
                            formatOptionLabel={(option) => option.label}
                            onChange={(selectedOptions) =>
                                setSelectedImage(selectedOptions.map((option) => option.value))
                            }
                        />
                    ) : ( <div className="container">
                        <div className="card">
                            <div
                                className="drop_box"
                                ref={dropArea}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                            >
                                <header ref={dragText}>
                                    <h4>Déposez votre fichier ici</h4>
                                </header>
                                {file && <p>Le fichier sélectionné est : {file.name}</p>}
                                <input type="file" hidden accept=".png,.jpeg,.gif" id="image" onChange={handleFileChange} />
                                <button className="btn" onClick={handleImageSelect}>Choisir une image</button>
                            </div>
                        </div>
                    </div>
            )}
                </div>
                <div className='select'>
                    <label htmlFor="location">Lieu:</label>
                    <Select
                        isMulti
                        value={selectedLocation.map((id) => ({
                            value: id,
                            label: locations.find((location) => location._id === id)?.title || "Chargement..."
                        }))}
                        options={locations.map((location) => ({
                            value: location._id,
                            label: location.title || "Chargement..."
                        }))}
                        onChange={(selectedOptions) => setSelectedLocation(selectedOptions.map((option) => option.value))}
                    />
                </div>

                <div className='select'>
                    <label htmlFor="riddle">Énigme:</label>
                    <Select isMulti value={selectedRiddle.map((id) => ({
                        value: id, label: riddles.find((riddle) => riddle._id === id)?.title || "Chargement..."
                    }))}
                        options={riddles.map((riddle) => ({
                            value: riddle._id,
                            label: riddle.title || "Chargement..."
                        }))}
                        onChange={(selectedOptions) => setSelectedRiddle(selectedOptions.map((option) => option.value))}
                    />
                </div>
                <div>
                    <label htmlFor="prev">Précedent:</label>
                    <input type="text" id="prev" value={prev} onChange={(e) => setPrev(e.target.value.toUpperCase())} />
                </div>
                <div>
                    <label htmlFor="next">Suivant:</label>
                    <input type="text" id="next" value={next} onChange={(e) => setNext(e.target.value.toUpperCase())} />
                </div>
                <button type="submit">Modifier le post</button>
            </form>
        </>
    );
}

export default PostEdit;

