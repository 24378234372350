import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';



export default function Informations(props) {
    const [isLogged, setIsLogged] = useState(Boolean(sessionStorage.getItem('accessToken')) || false);
    const [lieu, setLieu] = useState(sessionStorage.getItem('lieu') || 'Lille Centre');
    const [time, setTime] = useState(sessionStorage.getItem('time') || 'De 1h à 1/2 journée pour +/- 8km');
    const [indice, setIndice] = useState(sessionStorage.getItem('indice') || `Chaque étape du parcours 02 comporte une énigme qui permet d'avancer dans l'aventure. Si vous ne parvenez pas à trouver la réponse au bout de 3 essais, un indice apparaitra alors juste au dessus. Pour être certain de ne rien rater de votre périple, n'hésitez pas à suivre Tales & Keys sur : `);


    const handleContentEditableLocation = (event) => {
        const newValue = event.target.innerText;
        setLieu(newValue);
        sessionStorage.setItem('lieu', newValue);
    };

    const handleContentEditableTime = (event) => {
        const newValue = event.target.innerText;
        setTime(newValue);
        sessionStorage.setItem('time', newValue);
    }

    return (
        <>
            <Helmet>
                <title>Informations</title>
            </Helmet>
            <div className='info'>
                <h1 className='info__header'>Informations</h1>
                <hr />
                <div className='info__container'>
                    <div className="info__container__sub">
                        <i className="fa-solid fa-map-location-dot"></i>
                        <p className='info__container__sub__title'>Lieu :</p>
                    </div>
                    <p className={`info__container__sub__desc ${isLogged ? 'editable' : ''}`} contentEditable={isLogged} onBlur={handleContentEditableLocation}>{lieu}</p>
                </div>
                <hr />
                <div className="info__container">
                    <div className="info__container__sub">
                        <i className="fa-solid fa-clock"></i>
                        <p className='info__container__sub__title'>Longueur & Durée :</p>
                    </div>
                    <p className={`info__container__sub__desc ${isLogged ? 'editable' : ''}`} contentEditable={isLogged} onBlur={handleContentEditableTime}>{time}</p>
                </div>
                <hr />
                <div className="info__container">
                    <div className="info__container__sub">
                        <i className="fa-sharp fa-solid fa-map-pin"></i>
                        <p className='info__container__sub__title'>Etapes :</p>
                    </div>
                    <p className={`info__container__sub__desc ${isLogged ? 'editable' : ''}`} contentEditable={isLogged}>12</p>
                </div>
                <hr />
                <div className="info__container">
                    <div className="info__container__sub">
                        <i className="fa-solid fa-shoe-prints"></i>
                        <p className='info__container__sub__title'>Dénivelé :</p>
                    </div>
                    <p className={`info__container__sub__desc ${isLogged ? 'editable' : ''}`} contentEditable={isLogged}>Facile</p>
                </div>
                <hr />
                <div className="info__container">
                    <div className="info__container__sub">
                        <i className="fa-solid fa-toilet"></i>
                        <p className='info__container__sub__title'>WC :</p>
                    </div>
                    <p className={`info__container__sub__desc ${isLogged ? 'editable' : ''}`} contentEditable={isLogged}>Cita-parc (K14) – « Les Tanneurs » (R31) – Palais Rihour (O28)</p>
                </div>
                <hr />
                <div className="info__equipement">
                    <div className="info__container__sub">
                        <i className="fa-solid fa-suitcase-rolling"></i>
                        <p className='info__container__sub__title'>Equipement :</p>
                    </div>
                    <ul className={`info__container__sub__list ${isLogged ? 'editable' : ''}`} contentEditable={isLogged}>
                        <li>Gourde</li>
                        <li>Tenue adaptée et à l’aise</li>
                        <li>Smartphone chargé à 100% ou batterie externe</li>
                        <li>Connexion internet</li>
                    </ul>
                </div>
                <hr />
                <div className="info__description">
                    <p className={`info__description__desc ${isLogged ? 'editable' : ''}`} contentEditable={isLogged}>A la manière des cartographies d’Antan, Isla Obscura vous propose de transcender votre parcours en vous laissant guider par vos émotions et vos envies. Les parcours ne sont pas obligatoires, ainsi, tous les noms des rues ne figurent pas sur l’atlas. Par soucis d’esthétique, mais aussi pour vous placer dans une immersion plus forte où l’imprévu peut avoir la part belle. Laissez-vous guider, perdez-vous, sortez des itinéraires et vivez l’instant présent.</p>
                </div>
                <hr />
                <div className='info__container'>
                    <div className="info__container__sub">
                        <i className="fa-solid fa-map-location-dot"></i>
                        <p className='info__container__sub__title'>Indices :</p>
                    </div>
                    <p className={`info__container__sub__desc ${isLogged ? 'editable' : ''}`} contentEditable={isLogged} onBlur={handleContentEditableLocation}>{indice}  
                    <Link to="https://www.instagram.com/talesandkeys" target="_blank">
                        Instagram
                    </Link></p>
                </div>
                <hr />
            </div>
        </>
    )
}
