import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import FormData from 'form-data';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import API from '../API';


function PostForm(props) {
  const { serverUrl } = useContext(API);

  const { notify, accessToken, setAccessToken } = props;
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [riddles, setRiddles] = useState([]);
  const [selectedRiddle, setSelectedRiddle] = useState([]);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [seeUploaded, setSeeUploaded] = useState(false);
  const formData = new FormData();
  const [selectedImage, setSelectedImage] = useState([]);
  const [prev, setPrev] = useState('');
  const [next, setNext] = useState('');
  const [showFileInput, setShowFileInput] = useState(true);
  const [dropArea, setDropArea] = useState(null);

  useEffect(() => {
    const dropArea = document.querySelector(".drop_box");
    if (dropArea) {
      const dragText = dropArea.querySelector("header");

      const handleDragOver = (e) => {
        e.preventDefault();
        dropArea.classList.add("dragging");
        dragText.textContent = "Relâchez le fichier";
      };

      const handleDragLeave = () => {
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
      };

      const handleDrop = (e) => {
        e.preventDefault();
        dropArea.classList.remove("dragging");
        dragText.textContent = "Déposez le fichier ici";
        const file = e.dataTransfer.files[0];
        setFile(file);
      };

      dropArea.addEventListener("dragover", handleDragOver);
      dropArea.addEventListener("dragleave", handleDragLeave);
      dropArea.addEventListener("drop", handleDrop);

      setDropArea(dropArea);
    }
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    dropArea.classList.add("dragging");
    dragText.textContent = "Relâchez le fichier";
  };

  const handleDragLeave = () => {
    dropArea.classList.remove("dragging");
    dragText.textContent = "Déposez le fichier ici";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    dropArea.classList.remove("dragging");
    dragText.textContent = "Déposez le fichier ici";
    const file = e.dataTransfer.files[0];
    setFile(file);
  };

  let dragText = useRef(null);

  //Met à jour le fichier quand il est séléctionné

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  //affiche le select si la checkbox est coché

  const handleCheckboxChange = () => {
    setSeeUploaded(!seeUploaded);
    setShowFileInput(!showFileInput);
  };


  //Fonction qui va être utilisé si une image doit être upload,
  //elle va utiliser l'API d'imgur pour récupérer un lien et le stocker dans la BDD

  const createImage = async () => {
    try {
      let imageUrl;

      //vérifie si la checkbox est coché, si oui il va récupérer l'id de l'image
      //pour sortir de la fonction

      if (seeUploaded === true) {
        imageUrl = selectedImage.map((id) => image.find((img) => img._id === id)._id
        );
        return imageUrl;

        //si la checkbox n'est pas coché il va prendre le fichier et l'upload sur imgur

      } else {
        const response = await axios({
          method: "post",
          url: "https://api.imgur.com/3/image",
          data: formData,
          headers: {
            Authorization: "Client-ID 41e0d680636e63d",
            "Content-Type": "multipart/form-data",
          },
        });

        //récupère l'URL de l'image et poste l'URL de l'image dans la collection

        imageUrl = response.data.data.link;

        const imageResponse = await axios.post(
          `${serverUrl}/image/create`,
          {
            url: imageUrl,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const imageId = imageResponse.data.image._id;

        return imageId;
      }

    } catch (error) {
      console.log(error);
    }
  };

  formData.append("image", file);

  const handleImageSelect = (event) => {
    event.preventDefault();
    document.getElementById('image').click();
  };

  //Fonction qui va créer l'annexe et l'ajouter à la collection annexe

  const handleSubmit = async (e) => {
    e.preventDefault();

    const imageId = await createImage();

    if (!imageId) {
      notify('Erreur lors de la création de l\'image');
      return;
    }

    console.log(code)

    const postData = {
      code: code,
      title: title,
      image: imageId,
      lieu: selectedLocation,
      enigme: selectedRiddle,
      prev: prev,
      next: next,
    };

    console.log(postData);

    try {
      const response = await axios.post(`${serverUrl}/post/create`, postData, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });  
      if (response.status === 200) {
        notify(`${response?.data?.message || ""}`, "success");
      }


    } catch (error) {
      setAccessToken("");
      sessionStorage.removeItem("accessToken");
      notify(`Une erreur est survenue ${error?.response?.data?.message || ""}`, "error");
    }
  };

  //Récupère les infortmations pour les afficher dans le select

  useEffect(() => {
    axios.get(`${serverUrl}/image`)
      .then(response => {
        setImage(response.data.Images);
      })
      .catch(error => {
        console.error(error);
      });
    axios.get(`${serverUrl}/lieu`)
      .then(response => {
        setLocations(response.data.Lieux);
      })
      .catch(error => {
        console.error(error);
      });

    axios.get(`${serverUrl}/enigme`)
      .then(response => {
        setRiddles(response.data.Enigmes);
      })
      .catch(error => {
        console.error(error);
      });
  }, [serverUrl]);

  return (
    <>
      <form onSubmit={handleSubmit} className="form-container">
        <div>
          <label htmlFor="code">Code:</label>
          <input type="text" id="code" value={code} onChange={(e) => setCode(e.target.value.toUpperCase())} />
        </div>
        <div>
          <label htmlFor="title">Titre:</label>
          <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className='select'>
          <div className='checkbox-align'>
            <label htmlFor="image" className='checkbox-align__label'>Choisir une image déjà importé:</label>
            <label htmlFor="checkbox" className='toggle-switch'>
              <input type="checkbox" name="seeUploadedCheckbox" id="seeUploadedCheckbox" className='toggle-input' onClick={handleCheckboxChange} />
              <label htmlFor="seeUploadedCheckbox" className='toggle-label'></label>
            </label>
          </div>
          {showFileInput && (
            <div className="container">
              <div className="card">
                <div
                  className="drop_box"
                  ref={dropArea}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <header ref={dragText}>
                    <h4>Déposez votre fichier ici</h4>
                  </header>
                  {file && <p>Le fichier sélectionné est : {file.name}</p>}
                  <input type="file" hidden accept=".png,.jpeg,.gif" id="image" onChange={handleFileChange} />
                    <button className="btn" onClick={handleImageSelect}>Choisir une image</button>
                </div>
              </div>
            </div>
          )}

          {seeUploaded ? (
            <Select
              className='select'
              isMulti
              value={selectedImage.map((id) => ({
                value: id,
                label: image.find((image) => image._id === id).url
              }))}
              options={image.map((image) => ({
                value: image._id,
                label: (
                  <div>
                    <img src={image.url} alt={image._id} style={{ height: "50px" }} />
                    <span>{image.url}</span>
                  </div>
                )
              }))}
              formatOptionLabel={(option) => option.label}
              onChange={(selectedOptions) =>
                setSelectedImage(selectedOptions.map((option) => option.value))
              }
            />
          ) : null}
        </div>
        <div className='select'>
          <label htmlFor="location">Lieu:</label>
          <Select isMulti value={selectedLocation.map((id) => ({
            value: id, label: locations.find((location) => location._id === id).title
          }))}
            options={locations.map((location) => ({
              value: location._id, label: location.title
            }))}
            onChange={(selectedOptions) => setSelectedLocation(selectedOptions.map((option) => option.value))}
          />
        </div>

        <div className='select'>
          <label htmlFor="riddle">Énigme:</label>
          <Select className='select' isMulti value={selectedRiddle.map((id) => ({
            value: id, label: riddles.find((riddle) => riddle._id === id).title
          }))}
            options={riddles.map((riddle) => ({
              value: riddle._id, label: riddle.title
            }))}
            onChange={(selectedOptions) => setSelectedRiddle(selectedOptions.map((option) => option.value))}
          />
        </div>
        <div>
          <label htmlFor="prev">Précedent:</label>
          <input type="text" id="prev" value={prev} onChange={(e) => setPrev(e.target.value.toUpperCase())} />
        </div>
        <div>
          <label htmlFor="next">Suivant:</label>
          <input type="text" id="next" value={next} onChange={(e) => setNext(e.target.value.toUpperCase())} />
        </div>
        <button type="submit">Créer le post</button>
      </form>
    </>
  );
}


export default PostForm;
