import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Annexe from './Annexes';
import Homepage from '../Pages/HomePage';
import Informations from './Informations';
import Index from './Index';
import DashboardPage from '../Pages/DashboardPage';
import { useSwipeable } from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faBook } from '@fortawesome/free-solid-svg-icons';

Modal.setAppElement('#root');

export default function Navbar() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenInfo, setModalIsOpenInfo] = useState(false);
  const [showAnnexes, setShowAnnexes] = useState(false);
  const [modalExited, setModalExited] = useState(true);
  const [showIndex, setShowIndex] = useState(true);
  const [isLogged, setIsLogged] = useState(Boolean(sessionStorage.getItem('accessToken')) || false);
    // const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken') || '');

  function openModal() {
    if (modalIsOpen) {
      setModalIsOpen(false);
    } else {
      setModalIsOpen(true);
    }
  }

  function handleSwipeDown() {
    closeModal();
    closeModalInfo();
  }

  const swipeHandlers = useSwipeable({
    onSwipedDown: handleSwipeDown,
    delta: 50,
    swipeDuration: 250
  });

  function closeModal() {
    setModalExited(true);
    setModalIsOpen(false);
  }

  function openModalInfo() {
    if (modalIsOpenInfo) {
      setModalIsOpenInfo(false);
    } else {
      setModalIsOpenInfo(true);
    }
  }

  function closeModalInfo() {
    setModalExited(true);
    setModalIsOpenInfo(false);
  }

  function showAnnexesList() {
    setShowAnnexes(true);
    setShowIndex(false);
  }

  function showIndexList() {
    setShowAnnexes(false);
    setShowIndex(true);
  }

  const customStyles = {
    content: {
      backgroundColor: '#232323',
      top: '10%',
      left: 'auto',
      right: 'auto',
      bottom: '0',
      border: 'none',
      width: '100%',
    },
  };

  return (
    <nav className="navbar">
      <div className="navbar__container">
        <button className="navbar__container__mobile-button" onClick={() => { openModalInfo(); closeModal(); }}>
          <FontAwesomeIcon className='navbar__container__mobile-button__icon' icon={faCircleInfo} />
        </button>
        <button className="navbar__container__mobile-button">
          <Link className="navbar__container__mobile-button" onClick={() => { closeModal(); closeModalInfo(); }} to="/">
            <FontAwesomeIcon className='navbar__container__mobile-button__icon' icon={faHome} />
          </Link>
        </button>
        <button className="navbar__container__mobile-button" onClick={() => { openModal(); closeModalInfo(); }}>
          <FontAwesomeIcon className='navbar__container__mobile-button__icon' icon={faBook} />
        </button>
        <div className='navbar__container__desktop'>
          <div className='navbar__container__desktop-logo'></div>
          <div className='navbar__container__desktop__links'>
            {
              isLogged ?
                <>
                  <Link className="navbar__container__desktop__links-button" onClick={() => { closeModal(); closeModalInfo(); }} to={"/"} element={<Homepage />}>Accueil</Link>
                  <Link className="navbar__container__desktop__links-button" onClick={() => { openModalInfo(); closeModal(); }}>Informations</Link>
                  <Link className="navbar__container__desktop__links-button" onClick={() => { openModal(); closeModalInfo(); }}>Annexes</Link>
                  <Link className='navbar__container__desktop__links-button' to={"/dashboard"} element={<DashboardPage />}>Dashboard</Link>
                </>
                :
                <>
                  <Link className="navbar__container__desktop__links-button" onClick={() => { closeModal(); closeModalInfo(); }} to={"/"} element={<Homepage />}>Accueil</Link>
                  <Link className="navbar__container__desktop__links-button" onClick={() => { openModalInfo(); closeModal(); }}>Informations</Link>
                  <Link className="navbar__container__desktop__links-button" onClick={() => { openModal(); closeModalInfo(); }}>Annexes</Link>
                </>
            }
          </div>
        </div>
      </div>
      <Modal closeTimeoutMS={500} classNames="modal"
        isOpen={modalIsOpenInfo}
        onRequestClose={closeModalInfo}
        style={{
          overlay: {
            backgroundColor: 'transparent',
          },
          content: {
            ...customStyles.content,
          },
        }}
      >
        <div className="modal__informations" {...swipeHandlers}>
          <Informations />
        </div>
      </Modal>

      <Modal closeTimeoutMS={500} classNames="modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'transparent',
          },
          content: {
            ...customStyles.content,
          },
        }}
      >
        <div {...swipeHandlers}>

        <div className="modal__header">
          <button className="modal__header__button__switch" onClick={showIndexList}>
            Index
          </button>
          <button className="modal__header__button__switch" onClick={showAnnexesList}>
            Annexes
          </button>
        </div>
        {showIndex && <Index />}
        {showAnnexes && <Annexe />}
        </div>
      </Modal>
    </nav>
  );
}